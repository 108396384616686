import { Atom, SHARED_VALIDATIONS } from ":mods";
export function createPasswordResetInputs(){

  const Password = Atom.Form.createInput("", {
    required: true,
    validators: (value) => {
      const err = SHARED_VALIDATIONS.password.validate(value);
      return err?.length > 0 ? { [err]: true } : undefined;
    },
  });
  const ConfirmPassword = Atom.Form.createInput("", {
    required: true,
    validators: (value) => {
      const original_password = Password.control.value;
      const err = (!!value && original_password !== value) && "Password not matched";
      return err?.length > 0 ? { [err]: true } : undefined;
    },
  });

  const Actions = Atom.Form.createFormActions({password:Password, confirm_password:ConfirmPassword });
  return {
    Actions,
    Password,
    ConfirmPassword
  };




  
}